import * as i0 from '@angular/core';
import { input, Component, ChangeDetectionStrategy, HostBinding, computed, signal } from '@angular/core';
import * as i1 from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CrmControlDirective, crmResolveExpression, crmKillEvent, crmIsMatchingString, CrmElementIdPipe } from 'common-module/core';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzCheckboxComponent, NzCheckboxWrapperComponent } from 'ng-zorro-antd/checkbox';
import { CrmFormDataInput2Component, CrmFormDataWrapper2Component } from 'common-module/form/fragments';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { chunk } from 'lodash-es';
import { NzRowDirective, NzColDirective } from 'ng-zorro-antd/grid';
import { map, switchMap as switchMap$1 } from 'rxjs';
import { switchMap, debounceTime, map as map$1, filter, take } from 'rxjs/operators';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { DatePipe } from '@angular/common';
import { NzAutocompleteComponent, NzAutocompleteTriggerDirective } from 'ng-zorro-antd/auto-complete';
import { NzInputGroupComponent, NzInputDirective, NzTextareaCountComponent } from 'ng-zorro-antd/input';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzInputNumberGroupComponent, NzInputNumberComponent } from 'ng-zorro-antd/input-number';
import { NzRadioGroupComponent, NzRadioComponent } from 'ng-zorro-antd/radio';
import { crmMapOptions } from 'common-module/common';
import { NzSelectComponent, NzOptionComponent } from 'ng-zorro-antd/select';
import { NzSwitchComponent } from 'ng-zorro-antd/switch';
function CrmCheckboxComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "crmTranslate");
  }
  if (rf & 2) {
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, ctx), " ");
  }
}
const _forTrack0 = ($index, $item) => $item.value;
function CrmCheckboxGroupComponent_For_3_For_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "label", 4);
    i0.ɵɵlistener("nzCheckedChange", function CrmCheckboxGroupComponent_For_3_For_2_Template_label_nzCheckedChange_0_listener() {
      const option_r2 = i0.ɵɵrestoreView(_r1).$implicit;
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.handleOptionClick(null, option_r2));
    });
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "crmTranslate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r2 = ctx.$implicit;
    i0.ɵɵproperty("nzValue", option_r2.value)("nzDisabled", option_r2.disabled)("nzChecked", option_r2.checked);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 4, option_r2.label), " ");
  }
}
function CrmCheckboxGroupComponent_For_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵrepeaterCreate(1, CrmCheckboxGroupComponent_For_3_For_2_Template, 3, 6, "label", 3, _forTrack0);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const chunk_r4 = ctx.$implicit;
    i0.ɵɵproperty("nzSpan", chunk_r4.span);
    i0.ɵɵadvance();
    i0.ɵɵrepeater(chunk_r4.options);
  }
}
function CrmFormDateComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-date", 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formControl", ctx_r0.resolvedControl())("placeholder", ctx_r0.view().placeholder)("size", ctx_r0.view().size)("allowClear", ctx_r0.view().allowClear)("borderless", ctx_r0.view().borderless)("format", ctx_r0.view().format)("showTime", ctx_r0.view().showTime);
  }
}
function CrmFormDateComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "date");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_1_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", (tmp_1_0 = i0.ɵɵpipeBind2(2, 1, ctx_r0.data(), ctx_r0.view().format)) !== null && tmp_1_0 !== undefined ? tmp_1_0 : "-", " ");
  }
}
function CrmFormInputComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-input", 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formControl", ctx_r0.resolvedControl())("prefix", ctx_r0.view().prefix)("suffix", ctx_r0.view().suffix)("placeholder", ctx_r0.view().placeholder)("compact", ctx_r0.view().compact)("size", ctx_r0.view().size)("borderless", ctx_r0.view().borderless)("type", ctx_r0.view().type)("autocomplete", ctx_r0.view().autocomplete)("presets", ctx_r0.view().presets)("addonAfter", ctx_r0.view().addonAfter)("addonBefore", ctx_r0.view().addonBefore);
  }
}
function CrmFormInputComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_1_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", (tmp_1_0 = ctx_r0.data()) !== null && tmp_1_0 !== undefined ? tmp_1_0 : "-", " ");
  }
}
function CrmFormPasswordComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-input", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    const suffix_r2 = i0.ɵɵreference(4);
    i0.ɵɵproperty("formControl", ctx_r0.resolvedControl())("suffix", suffix_r2)("placeholder", ctx_r0.view().placeholder)("compact", ctx_r0.view().compact)("size", ctx_r0.view().size)("borderless", ctx_r0.view().borderless)("autocomplete", ctx_r0.view().autocomplete)("type", ctx_r0.type());
  }
}
function CrmFormPasswordComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵtext(1, "********");
    i0.ɵɵelementEnd();
  }
}
function CrmFormPasswordComponent_ng_template_3_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 6);
    i0.ɵɵlistener("click", function CrmFormPasswordComponent_ng_template_3_Conditional_0_Template_span_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r0 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r0.type.set("text"));
    });
    i0.ɵɵelementEnd();
  }
}
function CrmFormPasswordComponent_ng_template_3_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 7);
    i0.ɵɵlistener("click", function CrmFormPasswordComponent_ng_template_3_Conditional_1_Template_span_click_0_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r0 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r0.type.set("password"));
    });
    i0.ɵɵelementEnd();
  }
}
function CrmFormPasswordComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CrmFormPasswordComponent_ng_template_3_Conditional_0_Template, 1, 0, "span", 4)(1, CrmFormPasswordComponent_ng_template_3_Conditional_1_Template, 1, 0, "span", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵconditional(ctx_r0.type() === "password" ? 0 : 1);
  }
}
function CrmFormNumberComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-number", 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formControl", ctx_r0.resolvedControl())("prefix", ctx_r0.view().prefix)("suffix", ctx_r0.view().suffix)("placeholder", ctx_r0.view().placeholder)("compact", ctx_r0.view().compact)("size", ctx_r0.view().size)("min", ctx_r0.view().min)("max", ctx_r0.view().max)("precision", ctx_r0.view().precision)("handlerHidden", ctx_r0.view().handlerHidden)("formatter", ctx_r0.view().formatter)("parser", ctx_r0.view().parser)("addonAfter", ctx_r0.view().addonAfter)("addonBefore", ctx_r0.view().addonBefore)("step", ctx_r0.view().step);
  }
}
function CrmFormNumberComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_1_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", (tmp_1_0 = ctx_r0.data()) !== null && tmp_1_0 !== undefined ? tmp_1_0 : "-", " ");
  }
}
function CrmRadioButtonComponent_For_2_Case_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 1);
    i0.ɵɵpipe(1, "crmElementId");
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "crmTranslate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("id", i0.ɵɵpipeBind2(1, 3, option_r1.value, "crm-radio-option"))("nzValue", option_r1.value);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(3, 6, option_r1.label), " ");
  }
}
function CrmRadioButtonComponent_For_2_Case_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 2);
    i0.ɵɵpipe(1, "crmElementId");
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "crmTranslate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("id", i0.ɵɵpipeBind2(1, 3, option_r1.value, "crm-radio-option"))("nzValue", option_r1.value);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(3, 6, option_r1.label), " ");
  }
}
function CrmRadioButtonComponent_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CrmRadioButtonComponent_For_2_Case_0_Template, 4, 8, "label", 1)(1, CrmRadioButtonComponent_For_2_Case_1_Template, 4, 8, "label", 2);
  }
  if (rf & 2) {
    let tmp_10_0;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵconditional((tmp_10_0 = ctx_r1.type()) === "radio" ? 0 : tmp_10_0 === "button" ? 1 : -1);
  }
}
const _forTrack1 = ($index, $item) => $item.id;
function CrmSelectComponent_For_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "nz-option", 1);
    i0.ɵɵpipe(1, "crmTranslate");
  }
  if (rf & 2) {
    const opt_r1 = ctx.$implicit;
    i0.ɵɵproperty("nzKey", opt_r1.id)("nzValue", opt_r1.value)("nzLabel", i0.ɵɵpipeBind1(1, 3, opt_r1.label));
  }
}
function CrmSelectComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "nz-option", 2);
    i0.ɵɵelement(1, "span", 3);
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "crmTranslate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(3, 1, ctx_r1.loadingMessage()), " ");
  }
}
function CrmFormSelectComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-select", 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formControl", ctx_r0.resolvedControl())("placeholder", ctx_r0.view().placeholder)("size", ctx_r0.view().size)("borderless", ctx_r0.view().borderless)("options", ctx_r0.view().options)("showArrow", ctx_r0.view().showArrow)("showSearch", ctx_r0.view().showSearch)("allowClear", ctx_r0.view().allowClear)("serverSearch", ctx_r0.view().serverSearch)("loading", ctx_r0.view().loading)("loadingMessage", ctx_r0.view().loadingMessage)("dropdownMatchSelectWidth", ctx_r0.view().dropdownMatchSelectWidth)("search", ctx_r0.view().search)("mode", ctx_r0.view().mode);
  }
}
function CrmFormSelectComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.formattedData(), " ");
  }
}
function CrmSwitchComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 2);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "crmTranslate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("crm-switch-label-disabled", ctx_r0.disabled());
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 3, ctx), " ");
  }
}
function CrmTextareaComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "nz-textarea-count", 0)(1, "textarea", 2);
    i0.ɵɵpipe(2, "crmTranslate");
    i0.ɵɵlistener("ngModelChange", function CrmTextareaComponent_Conditional_0_Template_textarea_ngModelChange_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.internalUpdateModel($event));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("nzMaxCharacterCount", ctx);
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r1.disabled())("nzStatus", ctx_r1.status())("placeholder", i0.ɵɵpipeBind1(2, 8, ctx_r1.placeholder()))("nzSize", ctx_r1.size())("nzBorderless", ctx_r1.borderless())("rows", ctx_r1.rows())("ngModel", ctx_r1.model());
  }
}
function CrmTextareaComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "textarea", 2);
    i0.ɵɵpipe(1, "crmTranslate");
    i0.ɵɵlistener("ngModelChange", function CrmTextareaComponent_Conditional_1_Template_textarea_ngModelChange_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.internalUpdateModel($event));
    });
    i0.ɵɵtext(2, "      ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r1.disabled())("nzStatus", ctx_r1.status())("placeholder", i0.ɵɵpipeBind1(1, 7, ctx_r1.placeholder()))("nzSize", ctx_r1.size())("nzBorderless", ctx_r1.borderless())("rows", ctx_r1.rows())("ngModel", ctx_r1.model());
  }
}
function CrmFormTextareaComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-textarea", 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formControl", ctx_r0.resolvedControl())("placeholder", ctx_r0.view().placeholder)("compact", ctx_r0.view().compact)("size", ctx_r0.view().size)("borderless", ctx_r0.view().borderless)("type", ctx_r0.view().type)("rows", ctx_r0.view().rows)("maxCharacterCount", ctx_r0.view().maxCharacterCount);
  }
}
function CrmFormTextareaComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_1_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", (tmp_1_0 = ctx_r0.data()) !== null && tmp_1_0 !== undefined ? tmp_1_0 : "-", " ");
  }
}
class CrmCheckboxComponent extends CrmControlDirective {
  constructor() {
    super(...arguments);
    this.label = input();
    this.hostClass = 'crm-checkbox';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmCheckboxComponent_BaseFactory;
      return function CrmCheckboxComponent_Factory(__ngFactoryType__) {
        return (ɵCrmCheckboxComponent_BaseFactory || (ɵCrmCheckboxComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmCheckboxComponent)))(__ngFactoryType__ || CrmCheckboxComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmCheckboxComponent,
      selectors: [["crm-checkbox"]],
      hostVars: 2,
      hostBindings: function CrmCheckboxComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        label: [1, "label"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 3,
      consts: [["nz-checkbox", "", 3, "ngModelChange", "nzDisabled", "ngModel"]],
      template: function CrmCheckboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "label", 0);
          i0.ɵɵlistener("ngModelChange", function CrmCheckboxComponent_Template_label_ngModelChange_0_listener($event) {
            return ctx.internalUpdateModel($event);
          });
          i0.ɵɵtemplate(1, CrmCheckboxComponent_Conditional_1_Template, 2, 3);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          let tmp_2_0;
          i0.ɵɵproperty("nzDisabled", ctx.disabled())("ngModel", ctx.model());
          i0.ɵɵadvance();
          i0.ɵɵconditional((tmp_2_0 = ctx.label()) ? 1 : -1, tmp_2_0);
        }
      },
      dependencies: [CrmTranslatePipe, NzCheckboxComponent, FormsModule, i1.NgControlStatus, i1.NgModel],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmCheckboxComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-checkbox',
      template: `
    <label
      nz-checkbox
      [nzDisabled]="disabled()"
      [ngModel]="model()"
      (ngModelChange)="internalUpdateModel($event)"
    >
      @if (label(); as label) {
        {{ label | crmTranslate }}
      }
    </label>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmTranslatePipe, NzCheckboxComponent, FormsModule]
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormCheckboxComponent extends CrmFormDataInput2Component {
  constructor() {
    super(...arguments);
    this.options = input();
    this.hostClass = 'crm-form-checkbox';
    this.view = computed(() => {
      const options = this.options() ?? {};
      return {
        label: options.label
      };
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormCheckboxComponent_BaseFactory;
      return function CrmFormCheckboxComponent_Factory(__ngFactoryType__) {
        return (ɵCrmFormCheckboxComponent_BaseFactory || (ɵCrmFormCheckboxComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormCheckboxComponent)))(__ngFactoryType__ || CrmFormCheckboxComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormCheckboxComponent,
      selectors: [["crm-form-checkbox"]],
      hostVars: 2,
      hostBindings: function CrmFormCheckboxComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: [1, "options"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 5,
      consts: [[3, "status", "error", "config"], [3, "formControl", "label"]],
      template: function CrmFormCheckboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper2", 0);
          i0.ɵɵelement(1, "crm-checkbox", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("status", ctx.status())("error", ctx.error())("config", ctx.resolvedConfig());
          i0.ɵɵadvance();
          i0.ɵɵproperty("formControl", ctx.resolvedControl())("label", ctx.view().label);
        }
      },
      dependencies: [CrmCheckboxComponent, ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmFormDataWrapper2Component],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormCheckboxComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-checkbox',
      template: `
    <crm-form-data-input-wrapper2
      [status]="status()"
      [error]="error()"
      [config]="resolvedConfig()"
    >
      <crm-checkbox
        [formControl]="resolvedControl()"
        [label]="view().label"
      ></crm-checkbox>
    </crm-form-data-input-wrapper2>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmCheckboxComponent, ReactiveFormsModule, CrmFormDataWrapper2Component]
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns checkbox form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormCheckbox = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormCheckboxComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
const CHECK_ALL_KEY = '__crm_check-all-checkbox-group__';
class CrmCheckboxGroupComponent extends CrmControlDirective {
  constructor() {
    super(...arguments);
    this.options = input([]);
    this.columns = input(1);
    this.checkAllAllowed = input(false);
    this.hostClass = 'crm-checkbox-group';
    this.options$ = toObservable(computed(() => this.options())).pipe(switchMap(resolvable => {
      return crmResolveExpression({
        resolvable,
        injector: this.injector
      });
    }));
    this.resolvedOptions = toSignal(this.options$, {
      initialValue: []
    });
    this.chunkOptions$ = toObservable(computed(() => ({
      options: this.resolvedOptions(),
      columns: this.columns(),
      checkAllAllowed: this.checkAllAllowed(),
      disabled: this.disabled(),
      value: this.model() ?? []
    }))).pipe(debounceTime(0), map(data => this.calcOptions(data)));
    this.chunkOptions = toSignal(this.chunkOptions$, {
      initialValue: [{
        span: 24,
        options: []
      }]
    });
    this.allOptions = computed(() => this.getAllOptions(this.chunkOptions()));
    this.isAllChecked = false;
  }
  handleOptionClick(event, option) {
    if (option.value === CHECK_ALL_KEY) {
      crmKillEvent(event ?? undefined);
      this.isAllChecked = !this.isAllChecked;
      const values = this.isAllChecked ? this.allOptions().map(({
        value
      }) => value) : [];
      this.internalUpdateModel(values);
      return;
    }
    const value = new Set(this.model() ?? []);
    if (value.has(option.value)) {
      value.delete(option.value);
    } else {
      value.add(option.value);
    }
    this.internalUpdateModel(Array.from(value));
  }
  calcOptions({
    columns,
    options,
    disabled,
    checkAllAllowed,
    value
  }) {
    const extendedOptions = checkAllAllowed ? [{
      value: CHECK_ALL_KEY,
      label: 'crm.form.checkboxGroup.checkAll'
    }, ...options] : options;
    const mod = extendedOptions.length % columns;
    const perChunk = Math.floor(extendedOptions.length / columns) + mod;
    const chunkOptions = chunk(extendedOptions, perChunk);
    const span = 24 / columns;
    const chunks = chunkOptions.map(chunkOption => ({
      span,
      options: chunkOption.map(option => ({
        ...option,
        checked: value.includes(option.value),
        disabled: disabled || option.disabled
      }))
    }));
    this.isAllChecked = this.getAllOptions(chunks).every(({
      checked
    }) => checked);
    const isAllCheckedOption = chunks[0]?.options?.find(({
      value
    }) => value === CHECK_ALL_KEY);
    if (isAllCheckedOption) {
      isAllCheckedOption.checked = this.isAllChecked;
    }
    return chunks;
  }
  getAllOptions(chunks) {
    return chunks.map(({
      options
    }) => options).flat().filter(({
      value
    }) => value !== CHECK_ALL_KEY);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmCheckboxGroupComponent_BaseFactory;
      return function CrmCheckboxGroupComponent_Factory(__ngFactoryType__) {
        return (ɵCrmCheckboxGroupComponent_BaseFactory || (ɵCrmCheckboxGroupComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmCheckboxGroupComponent)))(__ngFactoryType__ || CrmCheckboxGroupComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmCheckboxGroupComponent,
      selectors: [["crm-checkbox-group"]],
      hostVars: 2,
      hostBindings: function CrmCheckboxGroupComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: [1, "options"],
        columns: [1, "columns"],
        checkAllAllowed: [1, "checkAllAllowed"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 0,
      consts: [[1, "crm-checkbox-group-wrapper"], ["nz-row", ""], ["nz-col", "", 3, "nzSpan"], ["nz-checkbox", "", 1, "crm-checkbox-group-option", 3, "nzValue", "nzDisabled", "nzChecked"], ["nz-checkbox", "", 1, "crm-checkbox-group-option", 3, "nzCheckedChange", "nzValue", "nzDisabled", "nzChecked"]],
      template: function CrmCheckboxGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "nz-checkbox-wrapper", 0)(1, "div", 1);
          i0.ɵɵrepeaterCreate(2, CrmCheckboxGroupComponent_For_3_Template, 3, 1, "div", 2, i0.ɵɵrepeaterTrackByIndex);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵrepeater(ctx.chunkOptions());
        }
      },
      dependencies: [CrmTranslatePipe, NzCheckboxWrapperComponent, NzCheckboxComponent, NzRowDirective, NzColDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmCheckboxGroupComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-checkbox-group',
      template: `
    <nz-checkbox-wrapper class="crm-checkbox-group-wrapper">
      <div nz-row>
        @for (chunk of chunkOptions(); track idx; let idx = $index) {
          <div nz-col [nzSpan]="chunk.span">
            @for (option of chunk.options; track option.value) {
              <label
                class="crm-checkbox-group-option"
                nz-checkbox
                [nzValue]="option.value"
                [nzDisabled]="option.disabled"
                [nzChecked]="option.checked"
                (nzCheckedChange)="handleOptionClick(null, option)"
              >
                {{ option.label | crmTranslate }}
              </label>
            }
          </div>
        }
      </div>
    </nz-checkbox-wrapper>
  `,
      imports: [CrmTranslatePipe, NzCheckboxWrapperComponent, NzCheckboxComponent, NzRowDirective, NzColDirective],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormCheckboxGroupComponent extends CrmFormDataInput2Component {
  constructor() {
    super(...arguments);
    this.options = input();
    this.hostClass = 'crm-form-checkbox-group';
    this.view = computed(() => {
      const options = this.options() ?? {};
      return {
        options: options.options ?? [],
        columns: options.columns ?? 1,
        checkAllAllowed: options.checkAllAllowed ?? false
      };
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormCheckboxGroupComponent_BaseFactory;
      return function CrmFormCheckboxGroupComponent_Factory(__ngFactoryType__) {
        return (ɵCrmFormCheckboxGroupComponent_BaseFactory || (ɵCrmFormCheckboxGroupComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormCheckboxGroupComponent)))(__ngFactoryType__ || CrmFormCheckboxGroupComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormCheckboxGroupComponent,
      selectors: [["crm-form-checkbox-group"]],
      hostVars: 2,
      hostBindings: function CrmFormCheckboxGroupComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: [1, "options"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 7,
      consts: [[3, "status", "error", "config"], [3, "formControl", "options", "columns", "checkAllAllowed"]],
      template: function CrmFormCheckboxGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper2", 0);
          i0.ɵɵelement(1, "crm-checkbox-group", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("status", ctx.status())("error", ctx.error())("config", ctx.resolvedConfig());
          i0.ɵɵadvance();
          i0.ɵɵproperty("formControl", ctx.resolvedControl())("options", ctx.view().options)("columns", ctx.view().columns)("checkAllAllowed", ctx.view().checkAllAllowed);
        }
      },
      dependencies: [ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmCheckboxGroupComponent, CrmFormDataWrapper2Component],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormCheckboxGroupComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-checkbox-group',
      template: `
    <crm-form-data-input-wrapper2
      [status]="status()"
      [error]="error()"
      [config]="resolvedConfig()"
    >
      <crm-checkbox-group
        [formControl]="resolvedControl()"
        [options]="view().options"
        [columns]="view().columns"
        [checkAllAllowed]="view().checkAllAllowed"
      ></crm-checkbox-group>
    </crm-form-data-input-wrapper2>
  `,
      imports: [ReactiveFormsModule, CrmCheckboxGroupComponent, CrmFormDataWrapper2Component],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns checkbox group form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormCheckboxGroup = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormCheckboxGroupComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
class CrmDateComponent extends CrmControlDirective {
  constructor() {
    super(...arguments);
    this.placeholder = input();
    this.size = input('large');
    this.allowClear = input(false);
    this.borderless = input(false);
    this.format = input('dd/MM/yyyy');
    this.showTime = input(false);
    this.hostClass = 'crm-date';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmDateComponent_BaseFactory;
      return function CrmDateComponent_Factory(__ngFactoryType__) {
        return (ɵCrmDateComponent_BaseFactory || (ɵCrmDateComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmDateComponent)))(__ngFactoryType__ || CrmDateComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmDateComponent,
      selectors: [["crm-date"]],
      hostVars: 2,
      hostBindings: function CrmDateComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        placeholder: [1, "placeholder"],
        size: [1, "size"],
        allowClear: [1, "allowClear"],
        borderless: [1, "borderless"],
        format: [1, "format"],
        showTime: [1, "showTime"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 11,
      consts: [[2, "width", "100%", 3, "ngModelChange", "nzStatus", "nzPlaceHolder", "nzSize", "nzAllowClear", "nzBorderless", "nzFormat", "nzShowTime", "nzDisabled", "ngModel"]],
      template: function CrmDateComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "nz-date-picker", 0);
          i0.ɵɵpipe(1, "crmTranslate");
          i0.ɵɵlistener("ngModelChange", function CrmDateComponent_Template_nz_date_picker_ngModelChange_0_listener($event) {
            return ctx.internalUpdateModel($event);
          });
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("nzStatus", ctx.status())("nzPlaceHolder", i0.ɵɵpipeBind1(1, 9, ctx.placeholder()))("nzSize", ctx.size())("nzAllowClear", ctx.allowClear())("nzBorderless", ctx.borderless())("nzFormat", ctx.format())("nzShowTime", ctx.showTime())("nzDisabled", ctx.disabled())("ngModel", ctx.model());
        }
      },
      dependencies: [CrmTranslatePipe, NzDatePickerComponent, FormsModule, i1.NgControlStatus, i1.NgModel],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmDateComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-date',
      template: `
    <nz-date-picker
      style="width: 100%"
      [nzStatus]="status()"
      [nzPlaceHolder]="placeholder() | crmTranslate"
      [nzSize]="size()"
      [nzAllowClear]="allowClear()"
      [nzBorderless]="borderless()"
      [nzFormat]="format()"
      [nzShowTime]="showTime()"
      [nzDisabled]="disabled()"
      [ngModel]="model()"
      (ngModelChange)="internalUpdateModel($event)"
    ></nz-date-picker>
  `,
      imports: [CrmTranslatePipe, NzDatePickerComponent, FormsModule],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormDateComponent extends CrmFormDataInput2Component {
  constructor() {
    super(...arguments);
    this.options = input();
    this.hostClass = 'crm-form-date';
    this.view = computed(() => {
      const options = this.options() ?? {};
      return {
        placeholder: options.placeholder,
        allowClear: options.allowClear ?? false,
        size: options.size ?? 'large',
        borderless: options.borderless ?? false,
        showTime: options.showTime ?? false,
        format: options.format ?? 'dd/MM/yyyy'
      };
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormDateComponent_BaseFactory;
      return function CrmFormDateComponent_Factory(__ngFactoryType__) {
        return (ɵCrmFormDateComponent_BaseFactory || (ɵCrmFormDateComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormDateComponent)))(__ngFactoryType__ || CrmFormDateComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormDateComponent,
      selectors: [["crm-form-date"]],
      hostVars: 2,
      hostBindings: function CrmFormDateComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: [1, "options"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 4,
      consts: [[3, "status", "error", "config"], [3, "formControl", "placeholder", "size", "allowClear", "borderless", "format", "showTime"], [1, "crm-form-date__read"]],
      template: function CrmFormDateComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper2", 0);
          i0.ɵɵtemplate(1, CrmFormDateComponent_Conditional_1_Template, 1, 7, "crm-date", 1)(2, CrmFormDateComponent_Conditional_2_Template, 3, 4, "div", 2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("status", ctx.status())("error", ctx.error())("config", ctx.config());
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.mode() === "edit" ? 1 : 2);
        }
      },
      dependencies: [ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmDateComponent, DatePipe, CrmFormDataWrapper2Component],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormDateComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-date',
      template: `
    <crm-form-data-input-wrapper2
      [status]="status()"
      [error]="error()"
      [config]="config()"
    >
      @if (mode() === 'edit') {
        <crm-date
          [formControl]="resolvedControl()"
          [placeholder]="view().placeholder"
          [size]="view().size"
          [allowClear]="view().allowClear"
          [borderless]="view().borderless"
          [format]="view().format"
          [showTime]="view().showTime"
        ></crm-date>
      } @else {
        <div class="crm-form-date__read">
          {{ (data() | date: view().format) ?? '-' }}
        </div>
      }
    </crm-form-data-input-wrapper2>
  `,
      imports: [ReactiveFormsModule, CrmDateComponent, DatePipe, CrmFormDataWrapper2Component],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns date form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormDate = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormDateComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
class CrmInputComponent extends CrmControlDirective {
  constructor() {
    super(...arguments);
    this.autocomplete = input('off');
    this.prefix = input();
    this.suffix = input();
    this.addonAfter = input();
    this.addonBefore = input();
    this.placeholder = input();
    this.presets = input();
    this.borderless = input(false);
    this.compact = input(true);
    this.type = input('text');
    this.size = input('large');
    this.hostClass = 'crm-input';
    this.presets$ = toObservable(computed(() => this.presets())).pipe(switchMap$1(resolvable => {
      return crmResolveExpression({
        resolvable,
        injector: this.injector
      });
    }));
    this.resolvedPresets = toSignal(this.presets$, {
      initialValue: []
    });
    this.filteredPresets = computed(() => {
      const value = this.model();
      const presets = this.resolvedPresets();
      if (!value?.length) {
        return presets;
      }
      return presets.filter(preset => crmIsMatchingString(preset, value));
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmInputComponent_BaseFactory;
      return function CrmInputComponent_Factory(__ngFactoryType__) {
        return (ɵCrmInputComponent_BaseFactory || (ɵCrmInputComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmInputComponent)))(__ngFactoryType__ || CrmInputComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmInputComponent,
      selectors: [["crm-input"]],
      hostVars: 2,
      hostBindings: function CrmInputComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        autocomplete: [1, "autocomplete"],
        prefix: [1, "prefix"],
        suffix: [1, "suffix"],
        addonAfter: [1, "addonAfter"],
        addonBefore: [1, "addonBefore"],
        placeholder: [1, "placeholder"],
        presets: [1, "presets"],
        borderless: [1, "borderless"],
        compact: [1, "compact"],
        type: [1, "type"],
        size: [1, "size"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 19,
      consts: [["autocompleteRef", ""], [3, "nzPrefix", "nzSuffix", "nzCompact", "nzStatus", "nzSize", "nzAddOnAfter", "nzAddOnBefore"], ["nz-input", "", 3, "ngModelChange", "autocomplete", "nzStatus", "disabled", "placeholder", "nzSize", "nzBorderless", "type", "nzAutocomplete", "ngModel"], [3, "nzDataSource"]],
      template: function CrmInputComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "nz-input-group", 1)(1, "input", 2);
          i0.ɵɵpipe(2, "crmTranslate");
          i0.ɵɵlistener("ngModelChange", function CrmInputComponent_Template_input_ngModelChange_1_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.internalUpdateModel($event));
          });
          i0.ɵɵelementEnd()();
          i0.ɵɵelement(3, "nz-autocomplete", 3, 0);
        }
        if (rf & 2) {
          const autocompleteRef_r2 = i0.ɵɵreference(4);
          i0.ɵɵproperty("nzPrefix", ctx.prefix())("nzSuffix", ctx.suffix())("nzCompact", ctx.compact())("nzStatus", ctx.status())("nzSize", ctx.size())("nzAddOnAfter", ctx.addonAfter())("nzAddOnBefore", ctx.addonBefore());
          i0.ɵɵadvance();
          i0.ɵɵproperty("autocomplete", ctx.autocomplete())("nzStatus", ctx.status())("disabled", ctx.disabled())("placeholder", i0.ɵɵpipeBind1(2, 17, ctx.placeholder()))("nzSize", ctx.size())("nzBorderless", ctx.borderless())("type", ctx.type())("nzAutocomplete", autocompleteRef_r2)("ngModel", ctx.model());
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("nzDataSource", ctx.filteredPresets());
        }
      },
      dependencies: [CrmTranslatePipe, NzInputGroupComponent, NzInputDirective, NzAutocompleteComponent, NzAutocompleteTriggerDirective, FormsModule, i1.DefaultValueAccessor, i1.NgControlStatus, i1.NgModel],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmInputComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-input',
      template: `
    <nz-input-group
      [nzPrefix]="prefix()"
      [nzSuffix]="suffix()"
      [nzCompact]="compact()"
      [nzStatus]="status()"
      [nzSize]="size()"
      [nzAddOnAfter]="addonAfter()"
      [nzAddOnBefore]="addonBefore()"
    >
      <input
        nz-input
        [autocomplete]="autocomplete()"
        [nzStatus]="status()"
        [disabled]="disabled()"
        [placeholder]="placeholder() | crmTranslate"
        [nzSize]="size()"
        [nzBorderless]="borderless()"
        [type]="type()"
        [nzAutocomplete]="autocompleteRef"
        [ngModel]="model()"
        (ngModelChange)="internalUpdateModel($event)"
      />
    </nz-input-group>

    <nz-autocomplete
      [nzDataSource]="filteredPresets()"
      #autocompleteRef
    ></nz-autocomplete>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmTranslatePipe, NzInputGroupComponent, NzInputDirective, NzAutocompleteComponent, NzAutocompleteTriggerDirective, FormsModule]
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormInputComponent extends CrmFormDataInput2Component {
  constructor() {
    super(...arguments);
    this.options = input();
    this.hostClass = 'crm-form-input';
    this.view = computed(() => {
      const options = this.options() ?? {};
      return {
        prefix: options.prefix,
        suffix: options.suffix,
        placeholder: options.placeholder ?? this.resolvedConfig().label,
        compact: options.compact ?? true,
        size: options.size ?? 'large',
        borderless: options.borderless ?? false,
        type: options.type ?? 'text',
        autocomplete: options.autocomplete ?? 'off',
        presets: options.presets,
        addonAfter: options.addonAfter,
        addonBefore: options.addonBefore
      };
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormInputComponent_BaseFactory;
      return function CrmFormInputComponent_Factory(__ngFactoryType__) {
        return (ɵCrmFormInputComponent_BaseFactory || (ɵCrmFormInputComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormInputComponent)))(__ngFactoryType__ || CrmFormInputComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormInputComponent,
      selectors: [["crm-form-input"]],
      hostVars: 2,
      hostBindings: function CrmFormInputComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: [1, "options"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 4,
      consts: [[3, "status", "error", "config"], [3, "formControl", "prefix", "suffix", "placeholder", "compact", "size", "borderless", "type", "autocomplete", "presets", "addonAfter", "addonBefore"], [1, "crm-form-input__read"]],
      template: function CrmFormInputComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper2", 0);
          i0.ɵɵtemplate(1, CrmFormInputComponent_Conditional_1_Template, 1, 12, "crm-input", 1)(2, CrmFormInputComponent_Conditional_2_Template, 2, 1, "div", 2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("status", ctx.status())("error", ctx.error())("config", ctx.config());
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.mode() === "edit" ? 1 : 2);
        }
      },
      dependencies: [ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmInputComponent, CrmFormDataWrapper2Component],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormInputComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-input',
      template: `
    <crm-form-data-input-wrapper2
      [status]="status()"
      [error]="error()"
      [config]="config()"
    >
      @if (mode() === 'edit') {
        <crm-input
          [formControl]="resolvedControl()"
          [prefix]="view().prefix"
          [suffix]="view().suffix"
          [placeholder]="view().placeholder"
          [compact]="view().compact"
          [size]="view().size"
          [borderless]="view().borderless"
          [type]="view().type"
          [autocomplete]="view().autocomplete"
          [presets]="view().presets"
          [addonAfter]="view().addonAfter"
          [addonBefore]="view().addonBefore"
        ></crm-input>
      } @else {
        <div class="crm-form-input__read">
          {{ data() ?? '-' }}
        </div>
      }
    </crm-form-data-input-wrapper2>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [ReactiveFormsModule, CrmInputComponent, CrmFormDataWrapper2Component]
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormPasswordComponent extends CrmFormDataInput2Component {
  constructor() {
    super(...arguments);
    this.options = input();
    this.hostClass = 'crm-form-password';
    this.type = signal('password');
    this.view = computed(() => {
      const options = this.options() ?? {};
      return {
        placeholder: options.placeholder ?? this.resolvedConfig().label,
        compact: options.compact ?? true,
        size: options.size ?? 'large',
        borderless: options.borderless ?? false,
        autocomplete: options.autocomplete ?? 'current-password'
      };
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormPasswordComponent_BaseFactory;
      return function CrmFormPasswordComponent_Factory(__ngFactoryType__) {
        return (ɵCrmFormPasswordComponent_BaseFactory || (ɵCrmFormPasswordComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormPasswordComponent)))(__ngFactoryType__ || CrmFormPasswordComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormPasswordComponent,
      selectors: [["crm-form-password"]],
      hostVars: 2,
      hostBindings: function CrmFormPasswordComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: [1, "options"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 4,
      consts: [["suffix", ""], [3, "status", "error", "config"], [3, "formControl", "suffix", "placeholder", "compact", "size", "borderless", "autocomplete", "type"], [1, "crm-form-input__read"], ["nz-icon", "", "nzType", "eye", "nzTheme", "outline"], ["nz-icon", "", "nzType", "eye-invisible", "nzTheme", "outline"], ["nz-icon", "", "nzType", "eye", "nzTheme", "outline", 3, "click"], ["nz-icon", "", "nzType", "eye-invisible", "nzTheme", "outline", 3, "click"]],
      template: function CrmFormPasswordComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper2", 1);
          i0.ɵɵtemplate(1, CrmFormPasswordComponent_Conditional_1_Template, 1, 8, "crm-input", 2)(2, CrmFormPasswordComponent_Conditional_2_Template, 2, 0, "div", 3);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(3, CrmFormPasswordComponent_ng_template_3_Template, 2, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵproperty("status", ctx.status())("error", ctx.error())("config", ctx.resolvedConfig());
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.mode() === "edit" ? 1 : 2);
        }
      },
      dependencies: [ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmInputComponent, NzIconDirective, CrmFormDataWrapper2Component],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormPasswordComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-password',
      template: `
    <crm-form-data-input-wrapper2
      [status]="status()"
      [error]="error()"
      [config]="resolvedConfig()"
    >
      @if (mode() === 'edit') {
        <crm-input
          [formControl]="resolvedControl()"
          [suffix]="suffix"
          [placeholder]="view().placeholder"
          [compact]="view().compact"
          [size]="view().size"
          [borderless]="view().borderless"
          [autocomplete]="view().autocomplete"
          [type]="type()"
        ></crm-input>
      } @else {
        <div class="crm-form-input__read">********</div>
      }
    </crm-form-data-input-wrapper2>

    <ng-template #suffix>
      @if (type() === 'password') {
        <span
          nz-icon
          nzType="eye"
          nzTheme="outline"
          (click)="type.set('text')"
        ></span>
      } @else {
        <span
          nz-icon
          nzType="eye-invisible"
          nzTheme="outline"
          (click)="type.set('password')"
        ></span>
      }
    </ng-template>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [ReactiveFormsModule, CrmInputComponent, NzIconDirective, CrmFormDataWrapper2Component]
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns input form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormInput = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormInputComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});

/**
 * Function which returns password form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormPassword = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormPasswordComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
class CrmNumberComponent extends CrmControlDirective {
  constructor() {
    super(...arguments);
    this.prefix = input();
    this.suffix = input();
    this.addonAfter = input();
    this.addonBefore = input();
    this.placeholder = input();
    this.size = input('large');
    this.compact = input(true);
    this.handlerHidden = input(false);
    this.precision = input(2);
    this.min = input();
    this.max = input();
    this.step = input();
    this.formatter = input();
    this.parser = input();
    this.minDefault = Number.MIN_VALUE;
    this.maxDefault = Number.MAX_VALUE;
    this.resolvedFormatter = computed(() => this.formatter() ?? (v => v));
    this.resolvedParser = computed(() => this.parser() ?? (v => v));
  }
  onFocus(input) {
    input.onModelChange(input.inputElement.nativeElement.value);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmNumberComponent_BaseFactory;
      return function CrmNumberComponent_Factory(__ngFactoryType__) {
        return (ɵCrmNumberComponent_BaseFactory || (ɵCrmNumberComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmNumberComponent)))(__ngFactoryType__ || CrmNumberComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmNumberComponent,
      selectors: [["crm-number"]],
      inputs: {
        prefix: [1, "prefix"],
        suffix: [1, "suffix"],
        addonAfter: [1, "addonAfter"],
        addonBefore: [1, "addonBefore"],
        placeholder: [1, "placeholder"],
        size: [1, "size"],
        compact: [1, "compact"],
        handlerHidden: [1, "handlerHidden"],
        precision: [1, "precision"],
        min: [1, "min"],
        max: [1, "max"],
        step: [1, "step"],
        formatter: [1, "formatter"],
        parser: [1, "parser"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 21,
      consts: [["input", ""], [2, "width", "100%", 3, "nzPrefix", "nzSuffix", "nzSize", "nzCompact", "nzStatus", "nzAddOnAfter", "nzAddOnBefore"], [2, "width", "100%", 3, "ngModelChange", "nzFocus", "nzStatus", "nzDisabled", "nzPlaceHolder", "nzMin", "nzMax", "nzStep", "nzPrecision", "nzFormatter", "nzParser", "ngModel"]],
      template: function CrmNumberComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "nz-input-number-group", 1)(1, "nz-input-number", 2, 0);
          i0.ɵɵpipe(3, "crmTranslate");
          i0.ɵɵlistener("ngModelChange", function CrmNumberComponent_Template_nz_input_number_ngModelChange_1_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.internalUpdateModel($event));
          })("nzFocus", function CrmNumberComponent_Template_nz_input_number_nzFocus_1_listener() {
            i0.ɵɵrestoreView(_r1);
            const input_r2 = i0.ɵɵreference(2);
            return i0.ɵɵresetView(ctx.onFocus(input_r2));
          });
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          let tmp_12_0;
          let tmp_13_0;
          let tmp_14_0;
          i0.ɵɵproperty("nzPrefix", ctx.prefix())("nzSuffix", ctx.suffix())("nzSize", ctx.size())("nzCompact", ctx.compact())("nzStatus", ctx.status())("nzAddOnAfter", ctx.addonAfter())("nzAddOnBefore", ctx.addonBefore());
          i0.ɵɵadvance();
          i0.ɵɵclassProp("crm-number-handler-hidden", ctx.handlerHidden() || ctx.suffix());
          i0.ɵɵproperty("nzStatus", ctx.status())("nzDisabled", ctx.disabled())("nzPlaceHolder", i0.ɵɵpipeBind1(3, 19, ctx.placeholder()))("nzMin", (tmp_12_0 = ctx.min()) !== null && tmp_12_0 !== undefined ? tmp_12_0 : ctx.minDefault)("nzMax", (tmp_13_0 = ctx.max()) !== null && tmp_13_0 !== undefined ? tmp_13_0 : ctx.maxDefault)("nzStep", (tmp_14_0 = ctx.step()) !== null && tmp_14_0 !== undefined ? tmp_14_0 : 1)("nzPrecision", ctx.precision())("nzFormatter", ctx.resolvedFormatter())("nzParser", ctx.resolvedParser())("ngModel", ctx.model());
        }
      },
      dependencies: [CrmTranslatePipe, NzInputNumberGroupComponent, NzInputNumberComponent, FormsModule, i1.NgControlStatus, i1.NgModel],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmNumberComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-number',
      template: `
    <nz-input-number-group
      style="width: 100%"
      [nzPrefix]="prefix()"
      [nzSuffix]="suffix()"
      [nzSize]="size()"
      [nzCompact]="compact()"
      [nzStatus]="status()"
      [nzAddOnAfter]="addonAfter()"
      [nzAddOnBefore]="addonBefore()"
    >
      <nz-input-number
        #input
        style="width: 100%"
        [class.crm-number-handler-hidden]="handlerHidden() || suffix()"
        [nzStatus]="status()"
        [nzDisabled]="disabled()"
        [nzPlaceHolder]="placeholder() | crmTranslate"
        [nzMin]="min() ?? minDefault"
        [nzMax]="max() ?? maxDefault"
        [nzStep]="step() ?? 1"
        [nzPrecision]="precision()"
        [nzFormatter]="resolvedFormatter()"
        [nzParser]="resolvedParser()"
        [ngModel]="model()"
        (ngModelChange)="internalUpdateModel($event)"
        (nzFocus)="onFocus(input)"
      ></nz-input-number>
    </nz-input-number-group>
  `,
      imports: [CrmTranslatePipe, NzInputNumberGroupComponent, NzInputNumberComponent, FormsModule],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class CrmFormNumberComponent extends CrmFormDataInput2Component {
  constructor() {
    super(...arguments);
    this.options = input();
    this.hostClass = 'crm-form-number';
    this.view = computed(() => {
      const options = this.options() ?? {};
      return {
        prefix: options.prefix,
        suffix: options.suffix,
        placeholder: options.placeholder,
        compact: options.compact ?? true,
        handlerHidden: options.handlerHidden ?? false,
        precision: options.precision ?? 2,
        size: options.size ?? 'large',
        min: options.min,
        max: options.max,
        addonAfter: options.addonAfter,
        addonBefore: options.addonBefore,
        step: options.step,
        parser: options.parser,
        formatter: options.formatter
      };
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormNumberComponent_BaseFactory;
      return function CrmFormNumberComponent_Factory(__ngFactoryType__) {
        return (ɵCrmFormNumberComponent_BaseFactory || (ɵCrmFormNumberComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormNumberComponent)))(__ngFactoryType__ || CrmFormNumberComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormNumberComponent,
      selectors: [["crm-form-number"]],
      hostVars: 2,
      hostBindings: function CrmFormNumberComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: [1, "options"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 4,
      consts: [[3, "status", "error", "config"], [3, "formControl", "prefix", "suffix", "placeholder", "compact", "size", "min", "max", "precision", "handlerHidden", "formatter", "parser", "addonAfter", "addonBefore", "step"], [1, "crm-form-number__read"]],
      template: function CrmFormNumberComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper2", 0);
          i0.ɵɵtemplate(1, CrmFormNumberComponent_Conditional_1_Template, 1, 15, "crm-number", 1)(2, CrmFormNumberComponent_Conditional_2_Template, 2, 1, "div", 2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("status", ctx.status())("error", ctx.error())("config", ctx.resolvedConfig());
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.mode() === "edit" ? 1 : 2);
        }
      },
      dependencies: [ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmNumberComponent, CrmFormDataWrapper2Component],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormNumberComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-number',
      template: `
    <crm-form-data-input-wrapper2
      [status]="status()"
      [error]="error()"
      [config]="resolvedConfig()"
    >
      @if (mode() === 'edit') {
        <crm-number
          [formControl]="resolvedControl()"
          [prefix]="view().prefix"
          [suffix]="view().suffix"
          [placeholder]="view().placeholder"
          [compact]="view().compact"
          [size]="view().size"
          [min]="view().min"
          [max]="view().max"
          [precision]="view().precision"
          [handlerHidden]="view().handlerHidden"
          [formatter]="view().formatter"
          [parser]="view().parser"
          [addonAfter]="view().addonAfter"
          [addonBefore]="view().addonBefore"
          [step]="view().step"
        ></crm-number>
      } @else {
        <div class="crm-form-number__read">
          {{ data() ?? '-' }}
        </div>
      }
    </crm-form-data-input-wrapper2>
  `,
      imports: [ReactiveFormsModule, CrmNumberComponent, CrmFormDataWrapper2Component],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns number form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormNumber = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormNumberComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
class CrmRadioButtonComponent extends CrmControlDirective {
  constructor() {
    super(...arguments);
    this.options = input();
    this.type = input('radio');
    this.buttonStyle = input('solid');
    this.hostClass = 'crm-radio-button';
    this.options$ = toObservable(computed(() => this.options() ?? [])).pipe(switchMap(resolvable => {
      return crmResolveExpression({
        resolvable,
        injector: this.injector
      });
    }));
    this.resolvedOptions = toSignal(this.options$);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmRadioButtonComponent_BaseFactory;
      return function CrmRadioButtonComponent_Factory(__ngFactoryType__) {
        return (ɵCrmRadioButtonComponent_BaseFactory || (ɵCrmRadioButtonComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmRadioButtonComponent)))(__ngFactoryType__ || CrmRadioButtonComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmRadioButtonComponent,
      selectors: [["crm-radio-button"]],
      hostVars: 2,
      hostBindings: function CrmRadioButtonComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: [1, "options"],
        type: [1, "type"],
        buttonStyle: [1, "buttonStyle"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 3,
      consts: [[1, "crm-form-radio-button-group", 3, "ngModelChange", "nzButtonStyle", "nzDisabled", "ngModel"], ["nz-radio", "", 1, "crm-form-radio-option", 3, "id", "nzValue"], ["nz-radio-button", "", 1, "crm-form-radio-button-option", 3, "id", "nzValue"]],
      template: function CrmRadioButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "nz-radio-group", 0);
          i0.ɵɵlistener("ngModelChange", function CrmRadioButtonComponent_Template_nz_radio_group_ngModelChange_0_listener($event) {
            return ctx.internalUpdateModel($event);
          });
          i0.ɵɵrepeaterCreate(1, CrmRadioButtonComponent_For_2_Template, 2, 1, null, null, _forTrack0);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("nzButtonStyle", ctx.buttonStyle())("nzDisabled", ctx.disabled())("ngModel", ctx.model());
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.resolvedOptions());
        }
      },
      dependencies: [CrmTranslatePipe, NzRadioGroupComponent, CrmElementIdPipe, NzRadioComponent, FormsModule, i1.NgControlStatus, i1.NgModel],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmRadioButtonComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-radio-button',
      template: `
    <nz-radio-group
      class="crm-form-radio-button-group"
      [nzButtonStyle]="buttonStyle()"
      [nzDisabled]="disabled()"
      [ngModel]="model()"
      (ngModelChange)="internalUpdateModel($event)"
    >
      @for (option of resolvedOptions(); track option.value) {
        @switch (type()) {
          @case ('radio') {
            <label
              class="crm-form-radio-option"
              nz-radio
              [id]="option.value | crmElementId: 'crm-radio-option'"
              [nzValue]="option.value"
            >
              {{ option.label | crmTranslate }}
            </label>
          }
          @case ('button') {
            <label
              class="crm-form-radio-button-option"
              nz-radio-button
              [id]="option.value | crmElementId: 'crm-radio-option'"
              [nzValue]="option.value"
            >
              {{ option.label | crmTranslate }}
            </label>
          }
        }
      }
    </nz-radio-group>
  `,
      imports: [CrmTranslatePipe, NzRadioGroupComponent, CrmElementIdPipe, NzRadioComponent, FormsModule],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormRadioButtonComponent extends CrmFormDataInput2Component {
  constructor() {
    super(...arguments);
    this.options = input();
    this.hostClass = 'crm-form-radio-button';
    this.view = computed(() => {
      const options = this.options() ?? {};
      return {
        options: options.options ?? [],
        buttonStyle: options.buttonStyle ?? 'solid',
        type: options.type ?? 'radio'
      };
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormRadioButtonComponent_BaseFactory;
      return function CrmFormRadioButtonComponent_Factory(__ngFactoryType__) {
        return (ɵCrmFormRadioButtonComponent_BaseFactory || (ɵCrmFormRadioButtonComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormRadioButtonComponent)))(__ngFactoryType__ || CrmFormRadioButtonComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormRadioButtonComponent,
      selectors: [["crm-form-radio-button"]],
      hostVars: 2,
      hostBindings: function CrmFormRadioButtonComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: [1, "options"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 7,
      consts: [[3, "status", "error", "config"], [3, "formControl", "options", "buttonStyle", "type"]],
      template: function CrmFormRadioButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper2", 0);
          i0.ɵɵelement(1, "crm-radio-button", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("status", ctx.status())("error", ctx.error())("config", ctx.resolvedConfig());
          i0.ɵɵadvance();
          i0.ɵɵproperty("formControl", ctx.resolvedControl())("options", ctx.view().options)("buttonStyle", ctx.view().buttonStyle)("type", ctx.view().type);
        }
      },
      dependencies: [ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmRadioButtonComponent, CrmFormDataWrapper2Component],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormRadioButtonComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-radio-button',
      template: `
    <crm-form-data-input-wrapper2
      [status]="status()"
      [error]="error()"
      [config]="resolvedConfig()"
    >
      <crm-radio-button
        [formControl]="resolvedControl()"
        [options]="view().options"
        [buttonStyle]="view().buttonStyle"
        [type]="view().type"
      ></crm-radio-button>
    </crm-form-data-input-wrapper2>
  `,
      imports: [ReactiveFormsModule, CrmRadioButtonComponent, CrmFormDataWrapper2Component],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns radio button form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormRadioButton = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormRadioButtonComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
class CrmSelectComponent extends CrmControlDirective {
  constructor() {
    super(...arguments);
    this.placeholder = input();
    this.size = input('large');
    this.borderless = input(false);
    this.showArrow = input(true);
    this.showSearch = input(false);
    this.options = input.required();
    this.loading = input(false);
    this.allowClear = input(false);
    this.serverSearch = input(false);
    this.dropdownMatchSelectWidth = input(false);
    this.loadingMessage = input('Loading...');
    this.mode = input('default');
    this.search = input();
    this.options$ = toObservable(computed(() => this.options() ?? [])).pipe(switchMap(resolvable => {
      return crmResolveExpression({
        resolvable,
        injector: this.injector
      });
    }), map$1(options => crmMapOptions(options)));
    this.resolvedOptions = toSignal(this.options$, {
      initialValue: []
    });
    this.isLoading$ = toObservable(computed(() => this.loading() ?? false)).pipe(switchMap(resolvable => {
      return crmResolveExpression({
        resolvable,
        injector: this.injector
      });
    }));
    this.isLoading = toSignal(this.isLoading$, {
      initialValue: false
    });
    this.compareWith = (o1, o2) => {
      if (typeof o1 === 'object' && o1 && 'id' in o1 && typeof o2 === 'object' && o2 && 'id' in o2) {
        return o1['id'] === o2['id'];
      }
      return o1 === o2;
    };
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmSelectComponent_BaseFactory;
      return function CrmSelectComponent_Factory(__ngFactoryType__) {
        return (ɵCrmSelectComponent_BaseFactory || (ɵCrmSelectComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmSelectComponent)))(__ngFactoryType__ || CrmSelectComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmSelectComponent,
      selectors: [["crm-select"]],
      inputs: {
        placeholder: [1, "placeholder"],
        size: [1, "size"],
        borderless: [1, "borderless"],
        showArrow: [1, "showArrow"],
        showSearch: [1, "showSearch"],
        options: [1, "options"],
        loading: [1, "loading"],
        allowClear: [1, "allowClear"],
        serverSearch: [1, "serverSearch"],
        dropdownMatchSelectWidth: [1, "dropdownMatchSelectWidth"],
        loadingMessage: [1, "loadingMessage"],
        mode: [1, "mode"],
        search: [1, "search"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 16,
      consts: [[2, "width", "100%", 3, "ngModelChange", "nzOnSearch", "nzPlaceHolder", "nzSize", "nzBorderless", "nzDisabled", "nzShowArrow", "nzAllowClear", "nzStatus", "nzShowSearch", "nzMode", "compareWith", "nzServerSearch", "nzDropdownMatchSelectWidth", "ngModel"], [3, "nzKey", "nzValue", "nzLabel"], ["nzCustomContent", "", "nzDisabled", ""], ["nz-icon", "", "nzType", "loading"]],
      template: function CrmSelectComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "nz-select", 0);
          i0.ɵɵpipe(1, "crmTranslate");
          i0.ɵɵlistener("ngModelChange", function CrmSelectComponent_Template_nz_select_ngModelChange_0_listener($event) {
            return ctx.internalUpdateModel($event);
          })("nzOnSearch", function CrmSelectComponent_Template_nz_select_nzOnSearch_0_listener($event) {
            let tmp_0_0;
            return (tmp_0_0 = ctx.search()) == null ? null : tmp_0_0($event);
          });
          i0.ɵɵrepeaterCreate(2, CrmSelectComponent_For_3_Template, 2, 5, "nz-option", 1, _forTrack1);
          i0.ɵɵtemplate(4, CrmSelectComponent_Conditional_4_Template, 4, 3, "nz-option", 2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("nzPlaceHolder", i0.ɵɵpipeBind1(1, 14, ctx.placeholder()))("nzSize", ctx.size())("nzBorderless", ctx.borderless())("nzDisabled", ctx.disabled())("nzShowArrow", ctx.showArrow())("nzAllowClear", ctx.allowClear())("nzStatus", ctx.status())("nzShowSearch", ctx.showSearch())("nzMode", ctx.mode())("compareWith", ctx.compareWith)("nzServerSearch", ctx.serverSearch())("nzDropdownMatchSelectWidth", ctx.dropdownMatchSelectWidth())("ngModel", ctx.model());
          i0.ɵɵadvance(2);
          i0.ɵɵrepeater(ctx.resolvedOptions());
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(ctx.isLoading() ? 4 : -1);
        }
      },
      dependencies: [CrmTranslatePipe, NzSelectComponent, NzOptionComponent, NzIconDirective, FormsModule, i1.NgControlStatus, i1.NgModel],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmSelectComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-select',
      template: `
    <nz-select
      style="width: 100%"
      [nzPlaceHolder]="placeholder() | crmTranslate"
      [nzSize]="size()"
      [nzBorderless]="borderless()"
      [nzDisabled]="disabled()"
      [nzShowArrow]="showArrow()"
      [nzAllowClear]="allowClear()"
      [nzStatus]="status()"
      [nzShowSearch]="showSearch()"
      [nzMode]="mode()"
      [compareWith]="compareWith"
      [nzServerSearch]="serverSearch()"
      [nzDropdownMatchSelectWidth]="dropdownMatchSelectWidth()"
      [ngModel]="model()"
      (ngModelChange)="internalUpdateModel($event)"
      (nzOnSearch)="search()?.($event)"
    >
      @for (opt of resolvedOptions(); track opt.id) {
        <nz-option
          [nzKey]="opt.id"
          [nzValue]="opt.value"
          [nzLabel]="opt.label | crmTranslate"
        ></nz-option>
      }
      @if (isLoading()) {
        <nz-option nzCustomContent nzDisabled>
          <span nz-icon nzType="loading"></span>
          {{ loadingMessage() | crmTranslate }}
        </nz-option>
      }
    </nz-select>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmTranslatePipe, NzSelectComponent, NzOptionComponent, NzIconDirective, FormsModule]
    }]
  }], null, null);
})();
class CrmFormSelectComponent extends CrmFormDataInput2Component {
  constructor() {
    super(...arguments);
    this.options = input();
    this.hostClass = 'crm-form-select';
    this.view = computed(() => {
      const options = this.options() ?? {};
      return {
        options: options.options ?? [],
        showArrow: options.showArrow ?? true,
        showSearch: options.showSearch ?? false,
        allowClear: options.allowClear ?? false,
        serverSearch: options.serverSearch ?? false,
        search: options.search,
        loading: options.loading ?? false,
        dropdownMatchSelectWidth: options.dropdownMatchSelectWidth ?? false,
        loadingMessage: options.loadingMessage ?? 'Loading...',
        placeholder: options.placeholder ?? this.resolvedConfig().label,
        size: options.size ?? 'large',
        borderless: options.borderless ?? false,
        mode: options.mode ?? 'default'
      };
    });
    this.formattedData$ = toObservable(computed(() => ({
      resolvable: this.options()?.dataFormatter,
      source: this.data(),
      mode: this.mode()
    }))).pipe(filter(({
      mode
    }) => mode === 'read'), switchMap(({
      resolvable,
      source
    }) => crmResolveExpression({
      resolvable: resolvable ?? source,
      source,
      injector: this.injector
    }).pipe(take(1))), map$1(data => data ?? '-'));
    this.formattedData = toSignal(this.formattedData$);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormSelectComponent_BaseFactory;
      return function CrmFormSelectComponent_Factory(__ngFactoryType__) {
        return (ɵCrmFormSelectComponent_BaseFactory || (ɵCrmFormSelectComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormSelectComponent)))(__ngFactoryType__ || CrmFormSelectComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormSelectComponent,
      selectors: [["crm-form-select"]],
      hostVars: 2,
      hostBindings: function CrmFormSelectComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: [1, "options"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 4,
      consts: [[3, "status", "error", "config"], [3, "formControl", "placeholder", "size", "borderless", "options", "showArrow", "showSearch", "allowClear", "serverSearch", "loading", "loadingMessage", "dropdownMatchSelectWidth", "search", "mode"], [1, "crm-form-select__read"]],
      template: function CrmFormSelectComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper2", 0);
          i0.ɵɵtemplate(1, CrmFormSelectComponent_Conditional_1_Template, 1, 14, "crm-select", 1)(2, CrmFormSelectComponent_Conditional_2_Template, 2, 1, "div", 2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("status", ctx.status())("error", ctx.error())("config", ctx.resolvedConfig());
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.mode() === "edit" ? 1 : 2);
        }
      },
      dependencies: [ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmSelectComponent, CrmFormDataWrapper2Component],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormSelectComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-select',
      template: `
    <crm-form-data-input-wrapper2
      [status]="status()"
      [error]="error()"
      [config]="resolvedConfig()"
    >
      @if (mode() === 'edit') {
        <crm-select
          [formControl]="resolvedControl()"
          [placeholder]="view().placeholder"
          [size]="view().size"
          [borderless]="view().borderless"
          [options]="view().options"
          [showArrow]="view().showArrow"
          [showSearch]="view().showSearch"
          [allowClear]="view().allowClear"
          [serverSearch]="view().serverSearch"
          [loading]="view().loading"
          [loadingMessage]="view().loadingMessage"
          [dropdownMatchSelectWidth]="view().dropdownMatchSelectWidth"
          [search]="view().search"
          [mode]="view().mode"
        ></crm-select>
      } @else {
        <div class="crm-form-select__read">
          {{ formattedData() }}
        </div>
      }
    </crm-form-data-input-wrapper2>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [ReactiveFormsModule, CrmSelectComponent, CrmFormDataWrapper2Component]
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns select form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormSelect = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormSelectComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
class CrmSwitchComponent extends CrmControlDirective {
  constructor() {
    super(...arguments);
    this.label = input();
    this.checkedChildren = input();
    this.unCheckedChildren = input();
    this.size = input('default');
    this.hostClass = 'crm-switch';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmSwitchComponent_BaseFactory;
      return function CrmSwitchComponent_Factory(__ngFactoryType__) {
        return (ɵCrmSwitchComponent_BaseFactory || (ɵCrmSwitchComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmSwitchComponent)))(__ngFactoryType__ || CrmSwitchComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmSwitchComponent,
      selectors: [["crm-switch"]],
      hostVars: 2,
      hostBindings: function CrmSwitchComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        label: [1, "label"],
        checkedChildren: [1, "checkedChildren"],
        unCheckedChildren: [1, "unCheckedChildren"],
        size: [1, "size"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 6,
      consts: [[1, "crm-switch-inner", 3, "ngModelChange", "nzSize", "nzDisabled", "nzCheckedChildren", "nzUnCheckedChildren", "ngModel"], [1, "crm-switch-label", 3, "crm-switch-label-disabled"], [1, "crm-switch-label"]],
      template: function CrmSwitchComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "nz-switch", 0);
          i0.ɵɵlistener("ngModelChange", function CrmSwitchComponent_Template_nz_switch_ngModelChange_0_listener($event) {
            return ctx.internalUpdateModel($event);
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(1, CrmSwitchComponent_Conditional_1_Template, 3, 5, "span", 1);
        }
        if (rf & 2) {
          let tmp_2_0;
          let tmp_3_0;
          let tmp_5_0;
          i0.ɵɵproperty("nzSize", ctx.size())("nzDisabled", ctx.disabled())("nzCheckedChildren", (tmp_2_0 = ctx.checkedChildren()) !== null && tmp_2_0 !== undefined ? tmp_2_0 : null)("nzUnCheckedChildren", (tmp_3_0 = ctx.unCheckedChildren()) !== null && tmp_3_0 !== undefined ? tmp_3_0 : null)("ngModel", ctx.model());
          i0.ɵɵadvance();
          i0.ɵɵconditional((tmp_5_0 = ctx.label()) ? 1 : -1, tmp_5_0);
        }
      },
      dependencies: [CrmTranslatePipe, NzSwitchComponent, FormsModule, i1.NgControlStatus, i1.NgModel],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmSwitchComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-switch',
      template: `
    <nz-switch
      class="crm-switch-inner"
      [nzSize]="size()"
      [nzDisabled]="disabled()"
      [nzCheckedChildren]="checkedChildren() ?? null"
      [nzUnCheckedChildren]="unCheckedChildren() ?? null"
      [ngModel]="model()"
      (ngModelChange)="internalUpdateModel($event)"
    ></nz-switch>

    @if (label(); as label) {
      <span
        class="crm-switch-label"
        [class.crm-switch-label-disabled]="disabled()"
      >
        {{ label | crmTranslate }}
      </span>
    }
  `,
      imports: [CrmTranslatePipe, NzSwitchComponent, FormsModule],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormSwitchComponent extends CrmFormDataInput2Component {
  constructor() {
    super(...arguments);
    this.options = input();
    this.hostClass = 'crm-form-switch';
    this.view = computed(() => {
      const options = this.options() ?? {};
      return {
        label: options.label,
        size: options.size ?? 'default',
        checkedChildren: options.checkedChildren ?? null,
        unCheckedChildren: options.unCheckedChildren ?? null
      };
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormSwitchComponent_BaseFactory;
      return function CrmFormSwitchComponent_Factory(__ngFactoryType__) {
        return (ɵCrmFormSwitchComponent_BaseFactory || (ɵCrmFormSwitchComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormSwitchComponent)))(__ngFactoryType__ || CrmFormSwitchComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormSwitchComponent,
      selectors: [["crm-form-switch"]],
      hostVars: 2,
      hostBindings: function CrmFormSwitchComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: [1, "options"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 8,
      consts: [[3, "status", "error", "config"], [3, "formControl", "label", "size", "checkedChildren", "unCheckedChildren"]],
      template: function CrmFormSwitchComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper2", 0);
          i0.ɵɵelement(1, "crm-switch", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("status", ctx.status())("error", ctx.error())("config", ctx.resolvedConfig());
          i0.ɵɵadvance();
          i0.ɵɵproperty("formControl", ctx.resolvedControl())("label", ctx.view().label)("size", ctx.view().size)("checkedChildren", ctx.view().checkedChildren)("unCheckedChildren", ctx.view().unCheckedChildren);
        }
      },
      dependencies: [ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmSwitchComponent, CrmFormDataWrapper2Component],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormSwitchComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-switch',
      template: `
    <crm-form-data-input-wrapper2
      [status]="status()"
      [error]="error()"
      [config]="resolvedConfig()"
    >
      <crm-switch
        [formControl]="resolvedControl()"
        [label]="view().label"
        [size]="view().size"
        [checkedChildren]="view().checkedChildren"
        [unCheckedChildren]="view().unCheckedChildren"
      ></crm-switch>
    </crm-form-data-input-wrapper2>
  `,
      imports: [ReactiveFormsModule, CrmSwitchComponent, CrmFormDataWrapper2Component],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns switch form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormSwitch = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormSwitchComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
class CrmTextareaComponent extends CrmControlDirective {
  constructor() {
    super(...arguments);
    this.placeholder = input();
    this.borderless = input(false);
    this.compact = input(true);
    this.type = input('text');
    this.size = input('large');
    this.rows = input();
    this.maxCharacterCount = input();
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmTextareaComponent_BaseFactory;
      return function CrmTextareaComponent_Factory(__ngFactoryType__) {
        return (ɵCrmTextareaComponent_BaseFactory || (ɵCrmTextareaComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmTextareaComponent)))(__ngFactoryType__ || CrmTextareaComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmTextareaComponent,
      selectors: [["crm-textarea"]],
      inputs: {
        placeholder: [1, "placeholder"],
        borderless: [1, "borderless"],
        compact: [1, "compact"],
        type: [1, "type"],
        size: [1, "size"],
        rows: [1, "rows"],
        maxCharacterCount: [1, "maxCharacterCount"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 1,
      consts: [[3, "nzMaxCharacterCount"], ["nz-input", "", 3, "disabled", "nzStatus", "placeholder", "nzSize", "nzBorderless", "rows", "ngModel"], ["nz-input", "", 3, "ngModelChange", "disabled", "nzStatus", "placeholder", "nzSize", "nzBorderless", "rows", "ngModel"]],
      template: function CrmTextareaComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrmTextareaComponent_Conditional_0_Template, 3, 10, "nz-textarea-count", 0)(1, CrmTextareaComponent_Conditional_1_Template, 3, 9, "textarea", 1);
        }
        if (rf & 2) {
          let tmp_0_0;
          i0.ɵɵconditional((tmp_0_0 = ctx.maxCharacterCount()) ? 0 : 1, tmp_0_0);
        }
      },
      dependencies: [CrmTranslatePipe, NzTextareaCountComponent, NzInputDirective, FormsModule, i1.DefaultValueAccessor, i1.NgControlStatus, i1.NgModel],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmTextareaComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-textarea',
      /**
       * duplicate textarea is not an optimal solution, but unfortunately using template outlet
       * is currently not an option. See more: https://github.com/NG-ZORRO/ng-zorro-antd/issues/5179
       */
      template: `
    @if (maxCharacterCount(); as count) {
      <nz-textarea-count [nzMaxCharacterCount]="count">
        <textarea
          nz-input
          [disabled]="disabled()"
          [nzStatus]="status()"
          [placeholder]="placeholder() | crmTranslate"
          [nzSize]="size()"
          [nzBorderless]="borderless()"
          [rows]="rows()"
          [ngModel]="model()"
          (ngModelChange)="internalUpdateModel($event)"
        ></textarea>
      </nz-textarea-count>
    } @else {
      <textarea
        nz-input
        [disabled]="disabled()"
        [nzStatus]="status()"
        [placeholder]="placeholder() | crmTranslate"
        [nzSize]="size()"
        [nzBorderless]="borderless()"
        [rows]="rows()"
        [ngModel]="model()"
        (ngModelChange)="internalUpdateModel($event)"
      >
      </textarea>
    }
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmTranslatePipe, NzTextareaCountComponent, NzInputDirective, FormsModule]
    }]
  }], null, null);
})();
class CrmFormTextareaComponent extends CrmFormDataInput2Component {
  constructor() {
    super(...arguments);
    this.options = input();
    this.hostClass = 'crm-form-text';
    this.view = computed(() => {
      const options = this.options() ?? {};
      return {
        placeholder: options.placeholder ?? this.resolvedConfig().label,
        compact: options.compact ?? true,
        size: options.size ?? 'large',
        borderless: options.borderless ?? false,
        type: options.type ?? 'text',
        rows: options.rows ?? 2,
        maxCharacterCount: options.maxCharacterCount
      };
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormTextareaComponent_BaseFactory;
      return function CrmFormTextareaComponent_Factory(__ngFactoryType__) {
        return (ɵCrmFormTextareaComponent_BaseFactory || (ɵCrmFormTextareaComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormTextareaComponent)))(__ngFactoryType__ || CrmFormTextareaComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormTextareaComponent,
      selectors: [["crm-form-text"]],
      hostVars: 2,
      hostBindings: function CrmFormTextareaComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: [1, "options"]
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 4,
      consts: [[3, "status", "error", "config"], [3, "formControl", "placeholder", "compact", "size", "borderless", "type", "rows", "maxCharacterCount"], [1, "crm-form-input__read"]],
      template: function CrmFormTextareaComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper2", 0);
          i0.ɵɵtemplate(1, CrmFormTextareaComponent_Conditional_1_Template, 1, 8, "crm-textarea", 1)(2, CrmFormTextareaComponent_Conditional_2_Template, 2, 1, "div", 2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("status", ctx.status())("error", ctx.error())("config", ctx.resolvedConfig());
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.mode() === "edit" ? 1 : 2);
        }
      },
      dependencies: [ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmTextareaComponent, CrmFormDataWrapper2Component],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormTextareaComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-text',
      template: `
    <crm-form-data-input-wrapper2
      [status]="status()"
      [error]="error()"
      [config]="resolvedConfig()"
    >
      @if (mode() === 'edit') {
        <crm-textarea
          [formControl]="resolvedControl()"
          [placeholder]="view().placeholder"
          [compact]="view().compact"
          [size]="view().size"
          [borderless]="view().borderless"
          [type]="view().type"
          [rows]="view().rows"
          [maxCharacterCount]="view().maxCharacterCount"
        ></crm-textarea>
      } @else {
        <div class="crm-form-input__read">
          {{ data() ?? '-' }}
        </div>
      }
    </crm-form-data-input-wrapper2>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [ReactiveFormsModule, CrmTextareaComponent, CrmFormDataWrapper2Component]
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns textarea form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormTextarea = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormTextareaComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});

/**
 * Generated bundle index. Do not edit.
 */

export { CrmCheckboxComponent, CrmCheckboxGroupComponent, CrmDateComponent, CrmFormCheckboxComponent, CrmFormCheckboxGroupComponent, CrmFormDateComponent, CrmFormInputComponent, CrmFormNumberComponent, CrmFormPasswordComponent, CrmFormRadioButtonComponent, CrmFormSelectComponent, CrmFormSwitchComponent, CrmFormTextareaComponent, CrmInputComponent, CrmNumberComponent, CrmRadioButtonComponent, CrmSelectComponent, CrmSwitchComponent, CrmTextareaComponent, crmGetFormCheckbox, crmGetFormCheckboxGroup, crmGetFormDate, crmGetFormInput, crmGetFormNumber, crmGetFormPassword, crmGetFormRadioButton, crmGetFormSelect, crmGetFormSwitch, crmGetFormTextarea };
